<template>
  <div>
    <!-- <div>
        <p>{{ this.$route.params.id }}</p>
      </div> -->
    <div class="card col-sm-12 col-md-12 p-1">
      <div>
        <div class="row">
          <div class="col-4 mt-1" style="text-align: start">
            <b-button variant="gradient-secondary" class="mr-1" @click="$router.push({ name: 'counting' })">กลับ</b-button>
          </div>
          <div class="col-4 mt-1" style="text-align: center">
            <div class="title head">
              <p class="h2" style="color: #558cef">รายการตรวจนับทรัพย์สิน</p>
            </div>
          </div>
          <div class="col-4" style="text-align: end">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <b-button variant="outline-primary"><feather-icon icon="ShareIcon" class="mr-50" />Export <feather-icon icon="ChevronDownIcon" class="mr-50" /></b-button>
              </template>
              <b-dropdown-item @click="exportExcel">
                <feather-icon icon="FileIcon" class="mr-50" />
                <span>Excel</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div>
          <hr />
          <div class="row justify-content-between">
            <div class="row">
              <b-form-group class="col-auto" label-cols="auto" label-cols-lg="auto"> <b-button class="btn btn-success" @click="post_data">บันทึก</b-button> </b-form-group
              ><b-form-group class="col-auto" label-cols="auto" label-cols-lg="auto">
                <b-button class="btn btn-danger" @click="getfrom" v-b-modal.modal-qrcode><feather-icon icon="GridIcon" class="mr-1" />สแกนคิวอาร์โค้ด</b-button>
              </b-form-group>
            </div>
            <b-modal id="modal-qrcode" title="สแกนคิวอาร์โค้ด" ok-only ok-title="ปิด">
              <b-card-text>
                <p class="error">{{ error }}</p>
                <p class="decode-result">
                  Last result: <b>{{ result }}</b>
                </p>
                <qrcode-stream @decode="onDecode" @init="onInit" />
              </b-card-text>
            </b-modal>
            <b-form-group class="mr-1 col-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input v-model="searchTerm" placeholder="ค้นหาจากรายการ ตรวจนับทรัพย์สิน" type="text" class="d-inline-block" />
              </div>
            </b-form-group>
          </div>
        </div>

        <!-- table -->
        <b-overlay :show="show" rounded="sm">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
            :line-numbers="true"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Action -->

              <span v-if="props.column.field === 'remark'">
                <span>
                  <b-input v-model="props.row.remark" @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)" />
                </span>
              </span>

              <div v-else-if="props.column.field === 'status'">
                <b-form-radio-group class="p-1 d-flex" :options="status_im" v-model="props.row.status" @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"> </b-form-radio-group>
              </div>

              <div v-else-if="props.column.field === 'performance'">
                <span v-if="props.row.status === 'สูญหาย'">
                  <b-form-radio-group disabled :options="performan_im" class="p-1 d-flex" v-model="props.row.status" @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"> </b-form-radio-group>
                </span>
                <span v-else>
                  <b-form-radio-group :options="performan_im" class="p-1 d-flex" v-model="props.row.performance" @change="changeCell(props.row[props.column.field], props.column.field, props.row.originalIndex)"> </b-form-radio-group
                ></span>
              </div>
              <div v-else-if="props.column.field === 'e_image'">
                <b-button variant="warning" v-b-modal="`modal-image${props.index}`">
                  <input ref="refInputEl" type="file" class="d-none" />
                  <feather-icon icon="ImageIcon" />
                </b-button>
                <b-modal :id="`modal-image${props.index}`" :title="`เพิ่มรูปภาพ${props.row.information}`" cancel-title="ยกเลิก" ok-title="ตกลง" @ok="image_post(props.row)">
                  <b-card-text>
                    <b-form-file v-model="file_image" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." multiple accept="image/*" />
                    <span>หมายเลขทรัพย์สิน: {{ props.row.e_number }}</span
                    ><br />
                    <span>สถานที่เก็บ: {{ props.row.place }}</span>
                  </b-card-text>
                </b-modal>
              </div>

              <div v-else-if="props.column.field === 'c_image'">
                <b-button variant="warning" v-b-modal="`modal-d_image${props.index}`" @click="get_image(props.row, 2)">
                  <input ref="refInputEl" type="file" class="d-none" />
                  <feather-icon icon="ImageIcon" />
                </b-button>
                <b-modal :id="`modal-d_image${props.index}`" :title="`รูปภาพ${props.row.information}`" cancel-title="ยกเลิก" ok-title="ตกลง">
                  <b-card-text>
                    <div class="text-center">
                      <p>แสดงรูปภาพตรวจนับ</p>
                      <div v-if="image_p != ''">
                        <swiper ref="mySwiper" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="swiperOptions">
                          <swiper-slide v-for="image_p in image_p" :key="image_p">
                            <b-img
                              max-width="100%"
                              height="100%"
                              src
                              :onerror="`fetch('${API}files/image/${image_p}',
                                                                                                                                                                                                                                                        {headers: {authorization:'${token}'}})
                        .then(r=>r.blob()).then(d=>
                        this.src=window.URL.createObjectURL(d))`"
                              class="mx-1"
                            />
                          </swiper-slide>
                          <div slot="button-next" class="swiper-button-next" />
                          <div slot="button-prev" class="swiper-button-prev" />
                          <div slot="pagination" class="swiper-pagination" />
                        </swiper>
                      </div>
                      <div v-else>ไม่มีรูป</div>
                    </div>

                    <span>หมายเลขทรัพย์สิน: {{ props.row.e_number }}</span
                    ><br />
                    <span>สถานที่เก็บ: {{ props.row.place }}</span>
                  </b-card-text>
                </b-modal>
              </div>
              <div v-else-if="props.column.field === 'b_image'">
                <b-button variant="warning" v-b-modal="`modal-aa_image${props.index}`" @click="get_image(props.row, 3)">
                  <input ref="refInputEl" type="file" class="d-none" />
                  <feather-icon icon="ImageIcon" />
                </b-button>
                <b-modal :id="`modal-aa_image${props.index}`" :title="`รูปภาพ${props.row.information}`" cancel-title="ยกเลิก" ok-title="ตกลง">
                  <b-card-text class="text-center">
                    <p>แสดงรูปภาพลงทะเบียน</p>

                    <div v-if="image_p != ''">
                      <swiper ref="mySwiper" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="swiperOptions">
                        <swiper-slide v-for="image_p in image_p" :key="image_p">
                          <b-img
                            width="250%"
                            height="250%"
                            src
                            :onerror="`fetch('${API}files/image/${image_p}',
                                                                                                                                                                                                                                        {headers: {authorization:'${token}'}})
                        .then(r=>r.blob()).then(d=>
                        this.src=window.URL.createObjectURL(d))`"
                            class="mx-1"
                          />
                        </swiper-slide>
                        <div slot="button-next" class="swiper-button-next" />
                        <div slot="button-prev" class="swiper-button-prev" />
                        <div slot="pagination" class="swiper-pagination" />
                      </swiper>
                    </div>
                    <div v-else>ไม่มีรูป</div>

                    <span>หมายเลขทรัพย์สิน: {{ props.row.e_number }}</span
                    ><br />
                    <span>สถานที่เก็บ: {{ props.row.place }}</span>
                  </b-card-text>
                </b-modal>
              </div>

              <!-- Column: Common -->
              <div v-else>
                {{ props.formattedRow[props.column.field] }}
              </div>
            </template>

            <!-- pagination -->
            <!-- <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select v-model="pageLength" :options="['5', '10', '15', '25', '50', '100']" class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value }, handleChangePage(value))" />
                    <span class="text-nowrap">of {{ total }} entries</span>
                  </div>
                  <div>
                    <b-pagination :value="1" :total-rows="total" :per-page="pageLength" first-number last-number
                      align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                      @input="handlePagechange($event)">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template> -->
          </vue-good-table>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue';
  import { BButton, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem } from 'bootstrap-vue';
  import { VueGoodTable } from 'vue-good-table';
  import { BFormDatepicker } from 'bootstrap-vue';
  import axios from 'axios';
  import API from '@/views/connectDB/condb.js';
  import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader';
  import { saveExcel } from '@progress/kendo-vue-excel-export';
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import 'swiper/css/swiper.css';
  import _ from 'lodash';
  import { async } from 'q';
  export default {
    components: {
      QrcodeStream,
      QrcodeDropZone,
      QrcodeCapture,
      BFormDatepicker,
      BButton,
      BCardCode,
      VueGoodTable,
      BAvatar,
      BBadge,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BDropdown,
      BDropdownItem,
      Swiper,
      SwiperSlide,
    },
    data() {
      return {
        swiperOptions: {
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'progressbar',
          },
        },
        image_view: '',
        image_p: '',
        file_image: null,
        result: '',
        error: '',
        API: `${API}`,
        status_im: [
          { text: 'มี', value: 'มี' },
          { text: 'สูญหาย', value: 'สูญหาย' },
        ],
        performan_im: [
          { text: 'ใช้ได้', value: 'ใช้ได้' },
          { text: 'ใช้ไม่ได้', value: 'ใช้ไม่ได้' },
          { text: 'ชำรุด', value: 'ชำรุด' },
        ],
        from: [
          {
            datenow: null,
            note: null,
            situation: null,
            efficiency: null,
          },
        ],
        show: false,
        pageLength: 100,
        currentPage: 1,
        total: '',
        dir: false,

        pee: '',
        pees: [
          { value: '', text: 'เลือกปีงบประมาณ:' },
          { value: '2559', text: '2559' },
          { value: '2560', text: '2560' },
          { value: '2561', text: '2561' },
        ],
        columns: [
          {
            label: 'หมายเลขทรัพย์สิน',
            field: 'e_number',
          },
          {
            label: 'รายการ',
            field: 'e_name',
          },

          {
            label: 'สถานที่เก็บ',
            field: 'place',
          },

          {
            label: 'สถานภาพ',
            field: 'status',
          },
          {
            label: 'สภาพ',
            field: 'performance',
          },
          {
            label: 'หมายเหตุ',
            field: 'remark',
          },
          {
            label: 'เพิ่มรูปภาพ',
            field: 'e_image',
          },
          {
            label: 'แสดงรูปภาพตรวจนับ',
            field: 'c_image',
          },
          {
            label: 'แสดงรูปภาพลงทะเบียน',
            field: 'b_image',
          },
        ],
        token: '',
        rows: [],
        searchTerm: '',
      };
    },
    async created() {
      // this.$router.push({ path: '/counting' }),
      console.log(this.$route.params.id.check_code);
      _.isEmpty(this.$route.params.id.check_code) ? this.$router.push({ path: '/counting' }) : true;

      const { access_token } = await this.access_token();
      this.token = access_token;
      this.countingAll();
    },
    methods: {
      exportExcel() {
        saveExcel({
          data: this.rows,
          fileName: 'รายการตรวจนับทรัพย์สิน',
          columns: [
            {
              title: 'รายการตรวจนับทรัพย์สิน',
              headerCellOptions: { textAlign: 'center' },
              children: [
                {
                  title: 'หมายเลขทรัพย์สิน',
                  field: 'e_number',
                },
                {
                  title: 'รายการ',
                  field: 'e_name',
                },

                {
                  title: 'สถานที่เก็บ',
                  field: 'place',
                },

                {
                  title: 'สถานภาพ',
                  field: 'status',
                },
                {
                  title: 'สภาพ',
                  field: 'performance',
                },
                {
                  title: 'หมายเหตุ',
                  field: 'remark',
                },
              ],
            },
          ],
        });
      },
      async get_image(image, index) {
        // console.log(index);
        this.image_p = '';
        // console.log(image);
        const { access_token } = await this.access_token();
        const url = `${API}equipmentImage?e_number=${image.e_number.replace('/', '_')}&check_code=${image.check_code}`;
        console.log(url);
        const head = {
          headers: {
            Authorization: await access_token,
          },
        };

        const res = await axios.get(url, head);
        // console.log(res.data.message.data);

        var image_type = '';

        if (index === 3) {
          image_type = res.data.message.data[0].registerimage;
        } else {
          image_type = res.data.message.data[0].checkimage;
        }
        // console.log(image_type);
        if (image_type != null && image_type != '') {
          // console.log('no');
          this.image_p = image_type;
          // console.log(JSON.parse(this.image_p));
          this.image_p = JSON.parse(this.image_p);
          this.image_p = this.image_p;
          // console.log(this.image_p);
        } else {
          this.image_p = '';
        }

        // console.log(this.image_p);
      },

      async image_post(value) {
        // console.log('2');
        const { e_number, check_code } = value;
        const { access_token } = await this.access_token();

        const url = `${API}equipmentImageCheck/${e_number.replace('/', '_')}&${check_code}`;
        let data = new FormData();
        this.file_image.forEach((element) => {
          // console.log(element);
          data.append('e_image', element);
        });
        // data.append('e_image', this.file_image[0]);

        const head = {
          headers: {
            Authorization: await access_token,
          },
        };
        const res = await axios.put(url, data, head);
        // console.log(res);
        // console.log(this.file_image.length);
        this.get_data_table();
      },
      onDecode(result) {
        this.result = result;
        this.searchTerm = this.result;
      },
      async onInit(promise) {
        try {
          await promise;
        } catch (error) {
          if (error.name === 'NotAllowedError') {
            this.error = 'ERROR: you need to grant camera access permission';
          } else if (error.name === 'NotFoundError') {
            this.error = 'ERROR: no camera on this device';
          } else if (error.name === 'NotSupportedError') {
            this.error = 'ERROR: secure context required (HTTPS, localhost)';
          } else if (error.name === 'NotReadableError') {
            this.error = 'ERROR: is the camera already in use?';
          } else if (error.name === 'OverconstrainedError') {
            this.error = 'ERROR: installed cameras are not suitable';
          } else if (error.name === 'StreamApiNotSupportedError') {
            this.error = 'ERROR: Stream API is not supported in this browser';
          } else if (error.name === 'InsecureContextError') {
            this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
          } else {
            this.error = `ERROR: Camera error (${error.name})`;
          }
        }
      },
      async countingAll() {
        this.show = true;
        console.log(this.$route.params.id);
        const { access_token } = await this.access_token();
        const url = `${API}countingAll?check_code=${this.$route.params.id.check_code}&work_gid=${this.$route.params.id.work_gid}&equipment_code=${this.$route.params.id.equipment_code}&place=${this.$route.params.id.place}&start_date=${this.$route.params.id.start_date}&stop_date=${this.$route.params.id.stop_date}`;

        const head = {
          headers: {
            Authorization: await access_token,
          },
        };
        console.log(url);
        const response = await axios.post(url, '', head);
        console.log(response);
        // console.log(head, url);
        if (response.data.status === 200) {
          this.get_data_table();
        } else {
          Swal.fire({
            position: 'top-center',
            icon: 'error',
            title: `${response.data.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.show = false;
      },
      async post_data() {
        const { access_token } = await this.access_token();
        const head = {
          headers: {
            Authorization: await access_token,
          },
        };
        this?.rows?.forEach(async (element) => {
          console.log(element);

          const url_a = `${API}checkEquipmentItemStatus`;
          const data_a = {
            e_number: element.e_number,
            performance: `${element.status === 'สูญหาย' ? 'สูญหาย' : element.performance}`,
          };
          console.log(data_a);
          const res_a = await axios.post(url_a, data_a, head);
          console.log(res_a);
        });

        const url = `${API}updateItemStatus/`;

        var aaa;
        var sss;
        this?.rows?.forEach(async (element) => {
          console.log(element);
          const data = new FormData();
          data.append('remark', element.remark);
          data.append('status', element.status);
          data.append('performance', `${element.status === 'สูญหาย' ? 'สูญหาย' : element.performance}`);
          data.append('e_image', element.e_image);
          aaa = await axios.put(url + element.id, data, head);
          sss++;
        });
        Swal.fire({
          position: 'top-center',
          icon: 'success',
          title: 'บันทึก',
          showConfirmButton: false,
          timer: 1500,
        });
        if (response[0].data.status === 200) {
          Swal.fire({
            position: 'top-center',
            icon: 'success',
            title: 'บันทึก',
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: 'top-center',
            icon: 'error',
            title: response[0].data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        // console.log(sss++);
      },
      changeCell(vaule, column, row) {
        this.rows[row][column] = vaule;
        // console.log(this.rows[row][column]);
      },
      handlePagechange(event) {
        this.currentPage = event;
        this.get_data_table();
      },
      handleChangePage(event) {
        this.pageLength = event;
        this.get_data_table();
      },
      async get_data_table() {
        const { access_token } = await this.access_token();
        this.show = true;
        const url = `${API}readEquipmentCheckList?_page=${this.currentPage}&_limit=${this.pageLength}&check_code=${this.$route.params.id.check_code}`;
        const head = {
          headers: {
            Authorization: await access_token,
          },
        };
        const res = await axios.get(url, head);
        console.log('------------------------------------------------------------------------------');
        console.log(res);
        let idx1 = Math.ceil(Number(res.data.message.total) / 100);

        const data1 = [];
        for (let index = 1; index <= idx1; index++) {
          console.log(index);
          const url1 = `${API}readEquipmentCheckList?_sort=-1&check_code=${this.$route.params.id.check_code}&_limit=${this.pageLength}&_page=${index}`;
          const res1 = await axios.get(url1, head);
          console.log(res1.data.message.data);
          data1.push(...res1.data.message.data);
        }
        // const created_data = res.data.message.data.map((res) => {
        //   return {
        //     check_code: this.$route.params.id.check_code,
        //     information: null,
        //     status: null,
        //     performance: '',
        //     ...res,
        //   }
        // })
        this.rows = data1;
        this.total = res.data.message.total;
        this.show = false;
      },

      async access_token() {
        return (
          await axios.post(`${API}permit`, '', {
            headers: {
              Authorization: localStorage.getItem('storedData') && JSON.parse(localStorage.getItem('storedData')),
            },
          })
        ).data.message;
      },

      getfrom() {
        (this.result = ''), (this.error = '');
      },
    },
  };
</script>
<style lang="scss">
  .error {
    font-weight: bold;
    color: red;
  }

  @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
